import { useState, useEffect } from "react";
import Header from "../components/header/header";
import NavBar from "../components/navbar/navbar";
import ContactMessages from "../components/contactMessage/contactMessage";
import ContactTable from "../components/contactUsTable/contactUsTable";
import UserMessage from "../components/userMessage/userMessage";
import UserTable from "../components/userTable/userTable";
import BodyAnalysis from "../components/bodyAnalysis/bodyAnalysis"
import Gym from "../components/gym/gym"
import Ramadan from "../components/ramadan/ramadan"
import Shred30 from "../components/shred30/shred30"
import ConceptHer from "../components/conceptHer/concetHer"
import Circuit from "../components/circuit/circuit"
import Gwlp from "../components/gwlp/gwlp"
import Revival from "../components/revival/revival"
import ChangePassword from '../components/changePassword/changePassword'
import Pregnant from "../components/pregnant/pregnant"
import { jwtDecode } from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Contact({ token }) {
  const [selectedNavItem, setSelectedNavItem] = useState('contact');
  const [userType, setUserType] = useState('user')
  const [firstTime, setFirstTime] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token_aos');

    if (!token) {
      window.location.href = '/';
    }
    const decodedToken = jwtDecode(token);
    console.log(decodedToken.user.isFirstTime, 'firstTime');
    setFirstTime(decodedToken.user.isFirstTime)

    const fetchData = async () => {
      const response = await fetch(`https://www.aos.fit/api/getToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: decodedToken.userId }),
      });
      if (response.ok) {
        const data = await response.json();

        localStorage.setItem('token_aos', data?.token);
      }
    }
    fetchData()
    setUserType(decodedToken.user.type)
  }, []);


  const handleNavItemClick = (itemName) => {
    console.log('handleNavItemClick', itemName);
    setSelectedNavItem(itemName);
  };

  return (
    <>
      <div className="stickyContainer">
        <Header token={token} />
        <div className="mainContainer">
          <NavBar userType={userType} onNavItemClick={handleNavItemClick} />
          {firstTime && (

            <ChangePassword isOpen={true} />
          )}
          <div className="tableContainer">
            {selectedNavItem === 'contact' && <ContactMessages />}
            {selectedNavItem === 'contact' && <ContactTable token={token} />}
            {selectedNavItem === 'user' && <UserMessage />}
            {selectedNavItem === 'user' && <UserTable />}
            {selectedNavItem === 'Body-Analysis' && <BodyAnalysis />}
            {selectedNavItem === 'The gym' && <Gym />}
            {selectedNavItem === 'ramadan' && <Ramadan />}
            {selectedNavItem === 'Shred-30' && <Shred30 />}
            {selectedNavItem === 'Concept Her' && <ConceptHer />}
            {selectedNavItem === 'Circuit' && <Circuit />}
            {selectedNavItem === 'Gwlp' && <Gwlp />}
            {selectedNavItem === 'Revival' && <Revival />}
            {selectedNavItem === 'Pregnant' && <Pregnant />}

          </div>
        </div>
      </div >
      <ToastContainer />
      <style jsx>{`
        html {
          background-color: #f1f4f8;
        }
        .stickyContainer {
          position: relative;
          z-index: 1; /* Ensure the header and navbar stay above other elements */
        }
        .mainContainer {
          display: flex;
          gap: 16px;
          flex-direction: row; /* Default is row, but added for clarity */
        }
.navBarContainer {
  position: fixed;
  width: 15%;
}
        .tableContainer {
          flex: 1; /* Takes the remaining space */
          padding: 20px;
        }
      `}</style>
    </>
  );
}
